import React, { useEffect } from "react";
import { MachineModalSteps } from "../../../interfaces/machinesInterfaces";
import { useStore } from "../../../storeManager/stores";
import { observer } from "mobx-react";
import { default as Modal } from "@mecha/design-components/dist/src/components/Elements/Modal";
import { cn } from "../../Hoc/tailwindHelper";
import AddMachineCode from "./Provisioning/AddMachineCode";
import ConfigureMachine from "./ConfigureMachine/ConfigureMachine";
import AddMachineMode from "./ConnectionMode/AddMachineMode";
import SelectMachineKind from "./ChooseKind/SelectMachineKind";
import EnableFeatures from "./EnableFeatures/EnableFeatures";

const AddMachineModal = () => {
  const {
    setAddMachineModalLoading,
    modalCurrentStep,
    handleAddModalClose,
    setCurrentMachineId,
    getMachineTemplates,
  } = useStore().machineStore;

  const modalContentJSX = {
    [MachineModalSteps.STEP1]: <SelectMachineKind />,
    [MachineModalSteps.STEP2]: <AddMachineMode />,
    [MachineModalSteps.STEP3]: <AddMachineCode />,
    [MachineModalSteps.STEP4]: <ConfigureMachine />,
    // [MachineModalSteps.STEP5]: <EnableFeatures />,
  };
  const modalTitleJSX = {
    [MachineModalSteps.STEP1]: (
      <p className="text-mob-h6 md:text-web-h6 font-medium text-light-n-400 dark:text-dark-n-400">
        Add Machine
      </p>
    ),
    [MachineModalSteps.STEP2]: (
      <p className="text-mob-h6 md:text-web-h6 font-medium text-light-n-400 dark:text-dark-n-400">
        Add Machine
      </p>
    ),
    [MachineModalSteps.STEP3]: (
      <p className="text-mob-h6 md:text-web-h6 font-medium text-light-n-400 dark:text-dark-n-400">
        Add Machine
      </p>
    ),
    [MachineModalSteps.STEP4]: (
      <p className="text-mob-h6 md:text-web-h6 font-medium text-light-n-400 dark:text-dark-n-400">
        Configure Machine
      </p>
    ),
    [MachineModalSteps.STEP5]: (
      <p className="text-mob-h6 md:text-web-h6 font-medium text-light-n-400 dark:text-dark-n-400">
        Enable Features
      </p>
    ),
  };

  const init = async () => {
    setAddMachineModalLoading(true);
    setCurrentMachineId("");
    // await getMachineTemplates();
    setAddMachineModalLoading(false);
  };

  useEffect(() => {
    init();
  }, [getMachineTemplates, setCurrentMachineId]);
  // Step indicator
  const StepIndicator = ({ step }) => {
    return (
      <div
        className={cn(
          "border-2 dark:border-neutral-350 flex items-center justify-center rounded-full w-5 h-5 lg:h-7 lg:w-7 text-light-n-500 dark:text-dark-n-500 ",
          { "dark:border-primary-600": step == modalCurrentStep },
          {
            "bg-primary-600 dark:border-primary-600 text-light-800 dark:text-dark-800":
              step < modalCurrentStep,
          },
        )}
      >
        <p className="text-[8px] lg:text-xs">{step}</p>
      </div>
    );
  };
  return (
    <Modal
      opened
      padding="0"
      radius="md"
      overlayColor="bg-black dark:bg-dark"
      overlayClass="z-999"
      className="z-999"
      closeOnOverlayClick={false}
      hideCloseButton={[
        MachineModalSteps.STEP1,
        MachineModalSteps.STEP2,
        MachineModalSteps.STEP3,
        MachineModalSteps.STEP4,
        MachineModalSteps.STEP5,
      ].includes(modalCurrentStep)}
      contentClassName="p-4 lg:p-8 md:w-[518px] xs:w-[335px] border-2 border-light-400 dark:border-dark-400 max-w-xl dark:bg-dark-800 bg-light-800 flex flex-col justify-between mecha-scroll-1"
      titleClassName="flex w-full s font-bold justify-between items-center"
      title={
        <div className="flex flex-row justify-between items-center w-full">
          <p className="dark:text-white">
            {modalTitleJSX[modalCurrentStep] ?? ""}
          </p>
          {modalCurrentStep < 5 && (
            <div className="flex flex-row gap-1 lg:gap-2">
              {[1, 2, 3, 4].map((step, index) => {
                return <StepIndicator step={step} key={index} />;
              })}
            </div>
          )}
        </div>
      }
      onClose={handleAddModalClose}
    >
      {modalContentJSX[modalCurrentStep] ?? ""}
    </Modal>
  );
};

export default observer(AddMachineModal);
