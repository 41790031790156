import { cn } from "../../../Hoc/tailwindHelper";

const ModeCard = ({ data, setSelectedMode, selectedMode }) => {
  const { title, id } = data;
  return (
    <button
      onClick={() => {
        setSelectedMode(data);
      }}
      className={cn(
        `px-3 py-4 rounded-lg bg-light-600 dark:bg-dark-600 border-2 dark:border-dark-760 border-light-760 w-full flex gap-2 items-center justify-between __machine-mode-${id}`,
        {
          "dark:border-primary-600 dark:bg-dark-800 bg-light-800":
            id === selectedMode.id,
        },
      )}
    >
      <h1
        className={cn(
          "text-mob-h9 md:text-web-h9 dark:text-dark-n-300 text-light-n-300",
          {
            "font-medium": id === selectedMode.id,
          },
        )}
      >
        {title}
      </h1>

      <div
        className={cn(
          "h-6 w-6 flex items-center justify-center rounded-full dark:border-dark-n-500 border-light-n-500 border-2 text-light-800 dark:text-dark-800",
          {
            "dark:bg-dark-b-500 bg-light-b-500 dark:border-dark-b-500 border-light-b-500":
              id === selectedMode.id,
          },
        )}
      >
        {id === selectedMode.id && (
          <svg
            width="8"
            height="6"
            viewBox="0 0 8 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 1L3 5L1 3"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
    </button>
  );
};

export default ModeCard;
