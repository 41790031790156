import React from "react";

const LinkedUrlIcon = (props: React.ComponentProps<"svg">) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M12.5003 6.66666H15.0003C16.8413 6.66666 18.3337 8.15905 18.3337 10C18.3337 11.8409 16.8413 13.3333 15.0003 13.3333H12.5003M6.66699 10H13.3337M7.50033 6.66666H5.00033C3.15938 6.66666 1.66699 8.15905 1.66699 10C1.66699 11.8409 3.15938 13.3333 5.00033 13.3333H7.50033"
      stroke="#3283E8"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LinkedUrlIcon;
