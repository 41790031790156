import { useState } from "react";
import { Button } from "@mecha/design-components/dist/src/components/Elements/Button";
import ConnectAppCard from "./ConnectApp";
import AgentCliCard from "./AgentCLI";
import ModeCard from "./ModeCard";
import { observer } from "mobx-react";
import {
  ADD_MACHINE_STEPS,
  MACHINE_SETUP_METHODS,
  modeArr,
} from "../AddMachineConstants";
import { useStore } from "../../../../storeManager/stores";
import Logger from "../../../../libs/Logger";
import LinkedUrlIcon from "../../../../assets/svg/LinkUrlIcon";
import Link from "next/link";
import { ADD_NEW_MACHINE_DOCS } from "../../../../config/linkConstants";

const AddMachineMode = () => {
  const log = new Logger({
    module: "Machines",
    component: "AddMachineMode",
  });
  log.trace({}, `user proceeds to choose mode of provisioning `);
  const { setModalCurrentStep, setAddMachineModal } = useStore().machineStore;
  const [selectedMode, setSelectedMode] = useState(modeArr[0]);

  const handleModalClose = () => {
    setModalCurrentStep(ADD_MACHINE_STEPS.STEP1);
    setAddMachineModal(false);
  };

  const modeContentJSX = {
    [MACHINE_SETUP_METHODS.USING_MECHANIX_CONNECT_APP]: <ConnectAppCard />,
    [MACHINE_SETUP_METHODS.USING_MECHA_AGENT_CLI]: <></>,
    // [MACHINE_SETUP_METHODS.USING_MECHA_AGENT_CLI]: <AgentCliCard />,
  };

  const handleSave = () => {
    log.trace({}, `user proceeds with ${selectedMode.id}`);
    setModalCurrentStep(ADD_MACHINE_STEPS.STEP3);
  };

  return (
    <div className="flex flex-col justify-between mt-3">
      <div className="flex flex-col items-center">
        <div className="relative w-full flex flex-col">
          <p className="text-light-n-500 dark:text-dark-n-500 text-web-label-3 text-mob-label-3 md:text-web-label-3">
            Choose Method
          </p>
          <div className="flex flex-col gap-3 mt-2">
            {modeArr.map((mode, index) => (
              <>
                <ModeCard
                  selectedMode={selectedMode}
                  setSelectedMode={setSelectedMode}
                  data={mode}
                  key={index}
                />
                <hr
                  className={`${
                    index == modeArr.length - 1 && "hidden"
                  } border-t-2 dark:border-dark-600 border-light-600`}
                />
              </>
            ))}
          </div>
          <div className="md:h-[295px] flex flex-col gap-3 mt-5">
            <h1 className="font-bold text-[18px] text-light-n-400 dark:text-dark-n-400">
              Steps to follow
            </h1>
            <div className="">{modeContentJSX[selectedMode.id]}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center mt-10">
        <Link target="_blank" href={ADD_NEW_MACHINE_DOCS}>
          <div className="flex flex-row items-center gap-2 cursor-pointer">
            <p className="text-mob-label-3 md:text-web-label-3 text-light-b-500 dark:text-dark-b-500 md:whitespace-nowrap">
              Adding new machine
            </p>
            <LinkedUrlIcon className="text-primary-600 hidden md:flex" />
          </div>
        </Link>
        <div className="flex w-full items-center justify-end gap-2">
          <Button
            color="secondary-default"
            onClick={handleModalClose}
            className="__add-machine-cancel"
          >
            Cancel
          </Button>
          <Button onClick={handleSave} className="__next-machine-mode" size="m">
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(AddMachineMode);
