const ConnectAppCard = () => {
  const connectAppArr = [
    "Launch the Connect App from the App Drawer",
    "Follow instructions on the screen and proceed to step that shows the code",
    "Continue to Next step to enter the displayed code",
  ];
  return (
    <div className="flex flex-col gap-6">
      {connectAppArr.map((step, index) => {
        return (
          <div
            className="flex flex-row gap-2 items-start text-mob-h9 md:text-web-h9 text-light-n-300 dark:text-dark-n-300"
            key={index}
          >
            <div className=" ">{index + 1}.</div>
            <p className="">{step}</p>
          </div>
        );
      })}
    </div>
  );
};

export default ConnectAppCard;
