import React, { useEffect, useState } from "react";
import { Button } from "@mecha/design-components/dist/src/components/Elements/Button";
import { useStore } from "../../../../storeManager/stores";
import { ADD_MACHINE_STEPS, PROVISIONING_STATES } from "../AddMachineConstants";
import InitialisingCard from "./InitialisingCard";
import SearchingCard from "./SearchingCard";
import ErrorCard from "./ErrorCard";
import ConnectedCard from "./ConnectedCard";
import { IMachineProvisionStatus } from "../../../../interfaces/machinesInterfaces";
import { getMachineProvisionStatus } from "../../../../api/machinesApis";
import Logger from "../../../../libs/Logger";
import { customToast } from "../../../../utils/customToast";
import LinkedUrlIcon from "../../../../assets/svg/LinkUrlIcon";
import Link from "next/link";
import { ADD_NEW_MACHINE_DOCS } from "../../../../config/linkConstants";

const AddMachineCode = () => {
  const log = new Logger({
    module: "Machines",
    component: "AddMachineCodeStep",
  });
  const {
    setModalCurrentStep,
    createMachineProvisioningRequest,
    getAllMachines,
    setAddMachineModal,
  } = useStore().machineStore;
  const [form, setForm] = useState({ code: "" });
  const [enableNext, setEnableNext] = useState(false);
  const [provisioningRequestId, setProvisioningRequestId] = useState(0);
  const [currentState, setCurrentState] = useState(
    PROVISIONING_STATES.INITIALISE,
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");

  const handleReset = () => {
    setIsProcessing(false);
    setForm({ code: "" });
    setCurrentState(PROVISIONING_STATES.INITIALISE);
  };

  const handleModalClose = () => {
    setModalCurrentStep(ADD_MACHINE_STEPS.STEP1);
    setAddMachineModal(false);
  };

  const handleProvisioningRequest = async () => {
    if (form.code.length === 6) {
      setCurrentState(PROVISIONING_STATES.SEARCHING);
      setIsProcessing(true);
      try {
        const requestBody = {
          provisionCode: form.code,
        };
        log.debug(
          requestBody,
          "submit() create machine provisioning request body",
        );
        const response = await createMachineProvisioningRequest(requestBody);
        if (!response.success) {
          throw response;
        }
        setProvisioningRequestId(response.payload.requestId);
        log.debug(response, "submit() create machine provisioning response");
        setTimeout(() => {
          checkStatus(response.payload.requestId);
          setCurrentState(PROVISIONING_STATES.SEARCHING);
        }, 2000);
      } catch (err) {
        const errMessage = err.message ? `${err.message}, please try again` : "please try again";
        setError(errMessage);
        customToast({
          msg: errMessage,
          toastType: "ERROR",
        });
        log.error({ err }, "Error on provisioning machine");
        setCurrentState(PROVISIONING_STATES.ERROR);
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const checkStatus = async (reqId = provisioningRequestId) => {
    try {
      const res = await getMachineProvisionStatus(reqId);
      log.debug(res, "checkStatus() machine status check");
      if (res) {
        const machineStatus = res?.payload?.status;
        if (machineStatus === IMachineProvisionStatus.COMPLETED) {
          setCurrentState(PROVISIONING_STATES.SUCCESS);
          customToast({
            msg: "Machine added successfully",
            toastType: "SUCCESS",
          });
          setEnableNext(true);
          getAllMachines();
        } else if (machineStatus === IMachineProvisionStatus.FAILED) {
          setCurrentState(PROVISIONING_STATES.ERROR);
        }
      }
    } catch (err) {
      setError(`${err.message}`);
      customToast({
        msg: err?.message,
        toastType: "ERROR",
      });
      setCurrentState(PROVISIONING_STATES.ERROR);
    }
  };

  const handleNext = () => {
    setModalCurrentStep(ADD_MACHINE_STEPS.STEP4);
  };

  const contentJSX = {
    [PROVISIONING_STATES.INITIALISE]: (
      <InitialisingCard
        isProcessing={isProcessing}
        form={form}
        setForm={setForm}
      />
    ),
    [PROVISIONING_STATES.SEARCHING]: (
      <SearchingCard isProcessing={isProcessing} code={form.code} oncancelRequest={handleReset} />
    ),
    [PROVISIONING_STATES.ERROR]: (
      <ErrorCard
        error={error}
        onCancel={handleReset}
      />
    ),
    [PROVISIONING_STATES.SUCCESS]: <ConnectedCard />,
  };

  useEffect(() => {
    if (currentState === PROVISIONING_STATES.SEARCHING && !isProcessing) {
      checkStatus();

      const intervalId = setInterval(() => {
        checkStatus();
      }, 5000);

      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        setCurrentState(PROVISIONING_STATES.ERROR);
      }, 60000);

      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [currentState, isProcessing]);

  useEffect(() => {
    handleProvisioningRequest();
  }, [form.code]);

  const isCancelButtonEnabled =
    currentState === PROVISIONING_STATES.INITIALISE ||
    currentState === PROVISIONING_STATES.SUCCESS;

  return (
    <div className="flex flex-col justify-between mt-3">
      <div className="flex flex-col items-center min-h-[450px]">
        <div className="relative w-full flex flex-col gap-5">
          <div className="w-full flex flex-col space-y-2">
            {contentJSX[currentState] ?? ""}
          </div>
          <hr className={`border-t-2 dark:border-dark-600 border-light-600`} />
          {currentState === PROVISIONING_STATES.SUCCESS && (
            <div className="flex items-center justify-between">
              <p className="dark:text-dark-n-300 text-light-n-300 text-mob-b5 md:text-web-b5 font-semibold">
                Continue to next step to finish the setup
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="mt-[10px] flex flex-row justify-between items-center">
        <Link target="_blank" href={ADD_NEW_MACHINE_DOCS}>
          <div className="flex flex-row items-center gap-2 cursor-pointer">
            <p className="text-mob-label-3 md:text-web-label-3 text-light-b-500 dark:text-dark-b-500 md:whitespace-nowrap">
              Adding new machine
            </p>
            <LinkedUrlIcon className="text-primary-600 hidden md:flex" />
          </div>
        </Link>
        <div className="flex w-full items-center justify-end gap-2">
          {isCancelButtonEnabled &&
            currentState !== PROVISIONING_STATES.SUCCESS && (
              <Button
                color="secondary-default"
                onClick={handleModalClose}
                className="__add-machine-cancel"
              >
                Cancel
              </Button>
            )}
          <Button
            disabled={!enableNext}
            onClick={handleNext}
            className="__next-machine-provisioning"
            size="m"
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddMachineCode;
