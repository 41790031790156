const ConnectedCard = () => {
  return (
    <div>
      <div className="p-1 rounded-lg flex flex-row gap-4 items-center">
        <img
          src="/assets/animations/add-machine-animations/provision-success-dark.gif"
          alt="machine-connected"
          className="w-[58px] h-[58px] rounded-md hidden dark:flex border-2 dark:border-dark-760"
        />
        <img
          src="/assets/animations/add-machine-animations/provision-success.gif"
          alt="machine-connected"
          className="w-[58px] h-[58px] rounded-md dark:hidden border-2 border-light-760"
        />
        <p className="dark:text-dark-n-200 text-light-n-200 text-mob-b4 md:text-web-b4 font-semibold">
          Machine connected to your account
        </p>
      </div>
    </div>
  );
};

export default ConnectedCard;
