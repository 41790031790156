import { cn } from "../../../Hoc/tailwindHelper";

const MachineList = ({
  data,
  selectedMachine,
  setSelectedMachine,
  isBorder = true,
}) => {
  const { name, iconUrl, keyValue } = data;
  return (
    <div
      onClick={() => {
        setSelectedMachine(keyValue);
      }}
      className={`w-full py-4 ${
        isBorder && "border-b-2"
      } flex flex-row justify-between items-center cursor-pointer dark:border-dark-600 border-light-600 __machines-list-${keyValue}`}
    >
      <div className="flex flex-row gap-2 items-center">
        <img src={`${iconUrl}`} alt={name} className="w-[30px] h-[30px]" />
        <h1 className="text-mob-h9 md:text-web-h9 text-light-n-300 dark:text-dark-n-300">
          {name}
        </h1>
      </div>
      <div className="mr-6">
        <div
          className={cn(
            "h-6 w-6 flex items-center justify-center rounded-full dark:border-dark-n-500 border-light-n-500 border-2 text-light-800 dark:text-dark-800",
            {
              "dark:bg-dark-b-500 bg-light-b-500 dark:border-dark-b-500 border-light-b-500":
                keyValue === selectedMachine,
            },
          )}
        >
          {keyValue === selectedMachine && (
            <svg
              width="8"
              height="6"
              viewBox="0 0 8 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 1L3 5L1 3"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
};

export default MachineList;
