import { Textbox } from "@mecha/design-components/dist/src/components/Elements/Textbox";
import React, { useEffect, useMemo, useRef, useState } from "react";
import InfoIcon from "../../../../assets/svg/InfoIcon";
import { Button } from "@mecha/design-components/dist/src/components/Elements/Button";
import { CheckAliasApi, updateTelemetry } from "../../../../api/machinesApis";
import Logger from "../../../../libs/Logger";
import { useStore } from "../../../../storeManager/stores";
import { ErrorReporter } from "../../../../libs/ErrorReporter";
import { customToast } from "../../../../utils/customToast";
import { EventTracker } from "../../../../libs/EventTracker";
import { ADD_MACHINE_STEPS } from "../AddMachineConstants";
import CheckCircleIcon from "../../../../assets/svg/CheckCircleIcon";
import useDebounce from "../../../../utils/services";
import Loader from "../../../../assets/svg/LoaderIcon";
import {
  defaultMachineImages,
} from "../../../../config/constants";
import axios from "axios";
import { fileToBase64 } from "../../../../utils/fileToBase64";
import { observer } from "mobx-react";
import { showErrorToast } from "../../../../utils/errorsToast";
import { EventTrackingCodes } from "../../../../interfaces/eventsInterfaces";
import Link from "next/link";
import { CONFIGURE_MACHINE_DOCS } from "../../../../config/linkConstants";


export enum ALIAS_ERRORS {
  ALREADY_EXIST = "This alias is not available, try a new one",
  LESSER_LENGTH = "Alias should be atleast 3 characters long",
}
const ConfigureMachine = () => {
  const log = new Logger({
    module: "Machines",
    component: "AddMachineConfigureStep",
  });
  log.trace({}, "(AddMachineConfigureStep) - Inside AddMachineConfigureStep");
  const {
    updateMachineById,
    machineInfo,
    setMachineInfo,
    getMachineById,
    provisionMachineId,
    setCurrentMachineId,
    setModalCurrentStep,
    getAllMachines,
    setAddMachineModal
  } = useStore().machineStore;

  const [isFetching, setFetchingData] = useState(true);
  const [machineName, setMachineName] = useState(`${machineInfo?.name || ""}`);
  const [machineIcon, setMachineIcon] = useState(
    machineInfo?.displayIconMediaPath || "",
  );
  const aRef = useRef(null);
  const [iconFileName, setIconFileName] = useState("");
  const [alias, setAlias] = useState("");
  // const [aliasSuggestion, setAliasSuggestion] = useState("");
  const [aliasAvailable, setAliasAvailable] = useState(true);
  const [aliasError, setAliasError] = useState("");
  const [validAlias, setValidAlias] = useState(true);
  const [inputChanged, setInputChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iconUpdated, setIconUpdated] = useState(false);
  const getMachineDetails = async () => {
    setFetchingData(true);
    EventTracker.track(EventTrackingCodes.ADD_MACHINE, {
      machineId: provisionMachineId,
    });
    try {
      const response = await getMachineById(provisionMachineId);
      const machineData = response.payload;
      log.debug(machineData, "(getMachineDetails()) - machine details ");
      setMachineInfo(machineData);
      setMachineName(machineData?.name);
      setAlias(machineData?.alias);
      const defaultAvatar = defaultMachineImages.find((img) => img.isDefault);

      setMachineIcon(
        response.payload?.displayIconMediaPath ||
          machineInfo?.displayIconMediaPath,
      );
      setIconFileName(defaultAvatar.name)
      setCurrentMachineId(machineData?.machineId || "");
    } catch (err) {
      log.error(
        { error: err },
        "(getMachineDetails()) - Error in getting machine details ",
      );
      ErrorReporter.collect(err, "Error in getting machine details");
      setFetchingData(false);
    }
    // Initialising machine telemetry config
    try {
      const reqObj = {
        isMetricsEnabled: true,
        isLogsEnabled: true,
      };
      await updateTelemetry(provisionMachineId, reqObj);
    } catch (err) {
      log.error(
        { error: err },
        "(getMachineDetails()) - Error in updating telemetry config",
      );
      ErrorReporter.collect(err, "Error in updating telemetry config", {
        machineId: provisionMachineId,
      });
    }
    setFetchingData(false);
  };

  const checkAliasAvailability = useRef(
    useDebounce(async (alias: string) => {
      log.trace(
        {},
        `(checkAliasAvailability) - checking availability for email: ${alias}`,
      );
      const { success: isAvailable } = await CheckAliasApi(alias).catch(err => {
        log.error(
          { err },
          `(checkAliasAvailability) - alias unavailable ${alias}`,
        );
        setAliasError(ALIAS_ERRORS.ALREADY_EXIST);
        setAliasAvailable(false);
        return { success: false };
      });
      log.info(
        { isAvailable },
        `(checkAliasAvailability) - alias can be updated to ${alias}`,
      );
      setAliasAvailable(isAvailable);

      isAvailable
        ? (() => {
            setAliasError("");
          })()
        : (() => {
            setAliasError(ALIAS_ERRORS.ALREADY_EXIST);
          })();

      setLoading(false);
    }, 1000),
  );

  const checkAliasValidity = useDebounce(async (alias: string) => {
    if (alias === machineInfo?.alias) {
      setValidAlias(true);
      setAliasError("");
      setAliasAvailable(true);
      setLoading(false);
      return;
    }
    log.debug(
      { alias },
      `(checkAliasValidity) - checking alias for validity {alias: ${alias}}`,
    );

    if (alias.length < 3) {
      setAliasError(ALIAS_ERRORS.LESSER_LENGTH);
      setLoading(false);
      return;
    }

    const aliasRegex = /^[a-z0-9](?!.*--)[a-z0-9-]{2,}[a-z0-9]$/;
    const isAliasValid = aliasRegex.test(alias);

    setValidAlias(isAliasValid);
    if (isAliasValid) {
      await checkAliasAvailability.current(alias);
    } else {
      setAliasError("Enter a valid alias");
      setAliasAvailable(false);
    }
    setLoading(false);
  }, 500);

  const handleOnNext = async () => {
    if (aliasError) {
      return;
    }
    const machineAlias = alias;
    let requestBody;
    requestBody = {
      name: machineName,
      alias: machineAlias,
    };
    if (iconUpdated) {
      requestBody = {
        ...requestBody,
        fileName: iconFileName
          ? `machine-pictures/${iconFileName}`
          : machineIcon,
        pictureBase64: machineIcon,
      };
    }
    log.debug(requestBody, "(handleOnNext()) - update machine details");
    setLoading(true);
    try {
      const response = await updateMachineById(requestBody);
        log.info({}, "(handleOnNext()) - Machine updated successfully");
        customToast({
          msg: "Machine saved successfully",
          toastType: "SUCCESS",
        });
        EventTracker.track("Machine updated", response);
        getAllMachines();
        setModalCurrentStep(ADD_MACHINE_STEPS.STEP1);
        setAddMachineModal(false);
    } catch (err) {
      log.error(
        { error: err },
        "(handleOnNext() updateMachineById()) - Error while updating machine ",
      );
      ErrorReporter.collect(err, "Error while updating machine");
      showErrorToast(err);
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = useMemo(
    () => loading || !machineName || !validAlias || aliasError.length > 0,
    [loading, machineName, alias, aliasError],
  );

  const handleSkip = () => {
    setModalCurrentStep(ADD_MACHINE_STEPS.STEP5);
  };

  const handleNameChange = value => {
    const sanitizedValue = value.replace(/[^a-zA-Z0-9\-@ ]+/g, "-");
    setMachineName(sanitizedValue);
    setInputChanged(true);
    const aliasSuggestion = value.toLowerCase().replace(/[^a-z0-9]+/g, "-");
    setAlias(aliasSuggestion);
    checkAliasValidity(aliasSuggestion);
  };

  const handleSelectDefaultAvatars = async (src: string, name: string) => {
    try {
      log.trace({}, "(handleSelectDefaultAvatars) - Default avatar selected");
      log.trace(
        {},
        `(handleSelectDefaultAvatars) - src: ${src}, name: ${name}`,
      );
      const response = await axios.get(src, { responseType: "blob" });
      const responseData = response.data;
      const base64 = await fileToBase64(responseData);
      log.trace(
        { base64 },
        "(handleSelectDefaultAvatars) - response for selected avatar",
      );
      setMachineIcon(base64);
      setIconFileName(name);
      aRef.current.value = null;
    } catch (error) {
      log.error(
        { error },
        "(handleSelectDefaultAvatars) - selecting avatar from default failed!",
      );
      ErrorReporter.collect(error, "Error while updating machine avatar");
    }
  };

  useEffect(() => {
    getMachineDetails();
  }, []);

  if (isFetching) {
    return (
      <div className="w-full flex items-center justify-center h-44">
        <Loader className="text-primary-500 w-10 h-10 inline mr-3 animate-spin" />
      </div>
    );
  }


  return (
    <div className="flex flex-col justify-between mt-3">
      <div className="flex flex-col items-center">
        <div className="w-full flex flex-col gap-5">
          <div className="flex flex-col gap-1">
            <p className="dark:text-dark-n-500 text-light-n-500 text-mob-h10 md:text-web-h10">
              Name
            </p>
            <Textbox
              placeholder="Name your machine"
              className=" __input-machine-name"
              value={machineName}
              fullWidth
              type="text"
              onChange={e => {
                handleNameChange(e.target.value);
              }}
              maxLength={25}
            />
          </div>
          <div className="w-full flex flex-col space-y-1">
            <div className="flex flex-row items-center space-x-2">
              <p className="dark:text-dark-n-500 text-light-n-500 text-mob-h10 md:text-web-h10">
                Alias
              </p>
            </div>
            <Textbox
              placeholder="mega-machina"
              fullWidth
              rightIcon={
                !aliasError &&
                aliasAvailable &&
                validAlias &&
                !loading &&
                inputChanged ? (
                  <CheckCircleIcon className="w-6 h-6 mr-2" color="#34C759" />
                ) : (
                  <></>
                )
              }
              type="text"
              name="traits.alias"
              maxLength={30}
              value={alias}
              className={`__input-machine-alias`}
              onChange={e => {
                // setAliasSuggestion("");
                setInputChanged(true);
                setAlias(e.target.value);
                checkAliasValidity(e.target.value);
              }}
            />
            {/* {aliasError && !loading && inputChanged && ( */}
            {aliasError && (
              // {aliasError && (
              <p className="dark:text-mecha-yellow-500 text-mecha-red-500 text-sm mt-1 __input-error-alias-exists">
                {aliasError}
              </p>
            )}
          </div>
        </div>
        <div className="mt-5 w-full md:h-[285px]">
          <p className="dark:text-dark-n-500 text-light-n-500 text-mob-h10 md:text-web-h10">
            Icon
          </p>
          <div className="max-w-max grid grid-cols-5 gap-2 mt-1 justify-items-center items-center ">
            {defaultMachineImages.map((item, idx) => (
              <button
                key={idx}
                onKeyPress={() => {
                  setIconUpdated(true);
                  handleSelectDefaultAvatars(item.src, item.name);
                }}
                onClick={() => {
                  setIconUpdated(true);
                  handleSelectDefaultAvatars(item.src, item.name);
                }}
                className={`cursor-pointer w-full aspect-square self-center justify-self-center flex items-center justify-center rounded-xl overflow-hidden border hover:border-2 hover:border-neutral-360 hover:dark:border-storm-dust-100 __machine_image_default_avatars_${idx} ${
                  iconFileName === item.name
                    ? "border-2 border-primary-200 shadow-c-200 dark:shadow-c-300"
                    : "border-dark-400 dark:border-dark-400 shadow-none"
                }`}
              >
                <img
                  src={item.src}
                  alt={`avatar-${item.id}`}
                  className="w-full h-full rounded-sm object-cover"
                />
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-10 flex flex-row justify-between items-center">
        <Link target="_blank" href={CONFIGURE_MACHINE_DOCS}>
          <div className="flex flex-row items-center gap-2 cursor-pointer">
            <InfoIcon className="w-4 h-4 text-primary-600" />
            <p className="text-xs md:text-sm text-primary-600">
              Configure your machine
            </p>
          </div>
        </Link>
        <div className="flex flex-row gap-5 items-center">
          <Button
            isLoading={loading}
            disabled={isDisabled}
            className="__next-configure-machine"
            size="m"
            onClick={handleOnNext}
          >
            Finish
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(ConfigureMachine);
