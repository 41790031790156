export const fileToBase64 = (file): Promise<string> => {
  return new Promise(resolve => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve((fileReader.result as string) || "");
    };

    fileReader.onerror = error => {
      console.error(error, "Error in fileToBase64");
      resolve("");
    };
  });
};
