import { Button } from "@mecha/design-components/dist/src/components/Elements/Button";
import { useEffect, useState } from "react";
import { Textbox } from "@mecha/design-components/dist/src/components/Elements/Textbox";
import SearchIcon from "../../../../assets/svg/SearchIcon";
import { useStore } from "../../../../storeManager/stores";
import { observer } from "mobx-react";
import { ADD_MACHINE_STEPS, machinesArr } from "../AddMachineConstants";
import MachineList from "./MachinesList";
import Logger from "../../../../libs/Logger";
import CancelIcon from "../../../../assets/svg/CancelIcon";
import LinkedUrlIcon from "../../../../assets/svg/LinkUrlIcon";
import Link from "next/link";
import { ADD_NEW_MACHINE_DOCS } from "../../../../config/linkConstants";

const SelectMachineKind = () => {
  const log = new Logger({
    module: "Machines",
    component: "SelectMachineKind",
  });
  log.trace({}, `user initialising to add machine`);
  const { setAddMachineKind, setModalCurrentStep, setAddMachineModal } =
    useStore().machineStore;
  const [filteredMachinesArr, setFilteredMachinesArr] = useState(machinesArr);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMachine, setSelectedMachine] = useState("");
  const handleMachineSearch = value => {
    const searchValue = value;
    setSearchTerm(searchValue);
    const searchedArr = machinesArr.filter(machine =>
      machine.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
    setFilteredMachinesArr(searchedArr);
  };

  const handleModalClose = () => {
    setModalCurrentStep(ADD_MACHINE_STEPS.STEP1);
    setAddMachineModal(false);
  };

  const handleSave = () => {
    log.trace({}, `user selected ${selectedMachine}`);
    setAddMachineKind(selectedMachine);
    setModalCurrentStep(ADD_MACHINE_STEPS.STEP2);
  };

  useEffect(() => {
    handleMachineSearch(searchTerm);
  }, [searchTerm]);

  return (
    <div className="flex flex-col justify-between mt-3">
      <div className="flex flex-col items-center">
        <div className="relative w-full flex flex-col gap-5">
          <div className="flex flex-col gap-2">
            <p className="text-light-n-500 dark:text-dark-n-500 text-web-label-3 text-mob-label-3 md:text-web-label-3">
              Select machine type
            </p>
            <Textbox
              fullWidth
              boxSize="m"
              type="text"
              name="search_machine"
              placeholder="Search machine"
              className="__input-machine-search-textbox"
              value={searchTerm}
              rightIcon={
                <CancelIcon
                  className={`${
                    searchTerm ? "flex" : "hidden"
                  } w-2.5 h-2.5 mr-1 text-light-n-400 dark:text-dark-n-400 cursor-pointer`}
                  onClick={() => setSearchTerm("")}
                />
              }
              leftIcon={
                <SearchIcon className="w-3.5 h-3.5  text-light-n-400 dark:text-dark-n-400 " />
              }
              onChange={e => setSearchTerm(e.target.value)}
            />
            {filteredMachinesArr.length === 0 && (
              <p className="mt-5 text-sm text-light-n-300 dark:text-dark-n-300">
                No machines found starting with &quot;
                {searchTerm.slice(0, 6) + "..."}&quot;
              </p>
            )}
          </div>
          <div className="">
            <div className="flex flex-col md:min-h-[300px]">
              {filteredMachinesArr.map((machine, index) => (
                <MachineList
                  selectedMachine={selectedMachine}
                  setSelectedMachine={setSelectedMachine}
                  data={machine}
                  key={index}
                />
              ))}
              <MachineList
                selectedMachine={selectedMachine}
                setSelectedMachine={setSelectedMachine}
                data={{
                  name: "Generic Linux",
                  iconUrl: "/assets/img/machine-kinds/generic-machine.png",
                  id: "generic-m",
                  keyValue: "GENERIC_MACHINE",
                }}
                isBorder={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 flex flex-row justify-between items-center">
        <Link target="_blank" href={ADD_NEW_MACHINE_DOCS}>
          <div className="flex flex-row items-center gap-2 cursor-pointer">
            <p className="text-mob-label-3 md:text-web-label-3 text-light-b-500 dark:text-dark-b-500 md:whitespace-nowrap">
              Adding new machine
            </p>
            <LinkedUrlIcon className="text-primary-600 hidden md:flex" />
          </div>
        </Link>
        <div className="flex w-full items-center justify-end gap-2">
          <Button
            color="secondary-default"
            onClick={handleModalClose}
            className="__add-machine-cancel"
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedMachine}
            className="__next-select-machine-kind"
            size="m"
            onClick={handleSave}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(SelectMachineKind);
