import { Button } from "@mecha/design-components/dist/src/components/Elements/Button";

const ErrorCard = ({ onCancel, error }) => {
  return (
    <div>
      <div className="w-full flex flex-row items-center justify-between">
        <div className="p-1 rounded-lg flex flex-row gap-4 items-center">
          <img
            src="/assets/animations/add-machine-animations/not-found-dark.gif"
            alt="error-provisioning"
            className="w-[58px] h-[58px] rounded-md hidden dark:flex border-2 dark:border-dark-760"
          />
          <img
            src="/assets/animations/add-machine-animations/not-found.gif"
            alt="error-provisioning"
            className="w-[58px] h-[58px] rounded-md dark:hidden border-2 border-light-760"
          />
          <p className="dark:text-dark-n-300 text-light-n-300 text-mob-b4 md:text-web-b4 font-semibold">
            Error adding your machine
          </p>
        </div>
        <Button color="tertiary-danger" onClick={onCancel} className="__button-retry">
          Retry
        </Button>
      </div>
      <div className="hidden __error-provisioning-machine">
        {error}
      </div>
      {/* <div className="mt-5 flex justify-between gap-3">
        <p className="text-mecha-red-110 text-sm self-start">{error}</p>
        <div className="flex flex-row items-center justify-end self-end gap-1">
          <Button color="secondary-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onRetry} className="__button-rest">
            Retry
          </Button>
        </div>
      </div> */}
    </div>
  );
};

export default ErrorCard;
