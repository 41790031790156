import OtpInput from "react-otp-input";

const InitialisingCard = ({ form, setForm, isProcessing }) => {
  const onChange = (key: keyof typeof form, value: string | number) => {
    setForm(prev => ({ ...prev, [key]: value }));
  };

  return (
    <div className="flex flex-col gap-5">
      <p className="text-light-n-500 dark:text-dark-n-500 text-web-label-3 text-mob-label-3 md:text-web-label-3">
        Enter the code displayed on the App or the CLI
      </p>
      <div className="overflow-x-auto">
        {/* @ts-ignore */}
        <OtpInput
          shouldAutoFocus={false}
          containerStyle="justify-between gap-2 pb-2"
          inputStyle={{
            outline: "none",
            borderRadius: "8px",
            backgroundColor: "transparent",
            width: "58px",
            height: "58px",
          }}
          placeholder="------"
          className="__otp-machine-input border-2 border-transparent focus-within:border-2 hover:border-2 hover:border-[#E4E7EE] dark:hover:border-[#202431] hover:dark:bg-dark-800 hover:bg-light-800 text-light-n-200 dark:text-dark-n-200  rounded-lg bg-light-760 dark:bg-dark-760 border-[#E4E7EE] dark:border-[#202431] focus-within:border-[#1973E0]"
          numInputs={6}
          value={form.code}
          errorStyle={{ border: "2px solid #E4002B" }}
          onChange={otpValue => onChange("code", otpValue.toUpperCase())}
          isDisabled={isProcessing}
        />
      </div>
    </div>
  );
};

export default InitialisingCard;
