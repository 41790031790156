import { Button } from "@mecha/design-components/dist/src/components/Elements/Button";

const SearchingCard = ({ code = "", oncancelRequest, isProcessing }) => {
  return (
    <div>
      <div className="flex flex-row items-center w-full justify-between">
      <div className="p-1 rounded-lg flex flex-row gap-4 items-center">
        <img
          src="/assets/animations/add-machine-animations/searching-machine-dark.gif"
          alt="searching-machine"
          className="w-[58px] h-[58px] rounded-md hidden dark:flex border-2 dark:border-dark-760"
        />
        <img
          src="/assets/animations/add-machine-animations/searching-machine.gif"
          alt="searching-machine"
          className="w-[58px] h-[58px] rounded-md dark:hidden border-2 border-light-760"
        />
        <p className="dark:text-dark-n-300 text-light-n-300 text-mob-b4 md:text-web-b4 font-semibold">
          {isProcessing ? "Processing request" : "Connecting to machine"}
        </p>
      </div>
        <Button color="tertiary-default" onClick={oncancelRequest}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default SearchingCard;
