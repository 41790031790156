import CometMIcon from "../../../assets/svg/CometMIcon";
import RaspberrypiIcon from "../../../assets/svg/RaspberrypiIcon";
import UbuntuLinuxIcon from "../../../assets/svg/UbuntuLinuxIcon";
import AmazonAmiIcon from "../../../assets/svg/AmazonAmiIcon";
import CentOSIcon from "../../../assets/svg/CentOSIcon";

export const machinesArr = [
  {
    name: "Comet-M on Mechanix OS",
    iconUrl: "/assets/img/machine-kinds/mecha-compute.png",
    id: "comet-m",
    keyValue: "COMET_M_MECHANIX",
  },
  {
    name: "Raspberry Pi",
    iconUrl: "/assets/img/machine-kinds/raspberry-pi.png",
    id: "raspberry_pi",
    keyValue: "RASPBERRY_PI",
  },
  {
    name: "Ubuntu Linux",
    iconUrl: "/assets/img/machine-kinds/linux.png",
    id: "ubuntu_linux",
    keyValue: "UBUNTU_LINUX",
  },
  {
    name: "Amazon AMI",
    iconUrl: "/assets/img/machine-kinds/amazon-ami.png",
    id: "amazon_ami",
    keyValue: "AMAZON_AMI",
  },
  {
    name: "CentOS",
    iconUrl: "/assets/img/machine-kinds/cent-os.png",
    id: "cent_os",
    keyValue: "CENT_OS",
  },
];

export enum MACHINE_SETUP_METHODS {
  USING_MECHANIX_CONNECT_APP = "connect_app",
  USING_MECHA_AGENT_CLI = "mecha_agent_cli",
}

export const modeArr = [
  {
    id: "connect_app",
    title: "Using the Connect App (GUI)",
  },
  // {
  //   id: "mecha_agent_cli",
  //   title: "Using the Terminal (CLI)",
  // },
];

export enum PROVISIONING_STATES {
  INITIALISE = "INITIALISE",
  SEARCHING = "SEARCHING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export enum ADD_MACHINE_STEPS {
  STEP1 = 1,
  STEP2 = 2,
  STEP3 = 3,
  STEP4 = 4,
  STEP5 = 5,
  STEP6 = 6,
}

export const MAXIMUM_MACHINE_LIMIT = 10;
